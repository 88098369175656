<template>
  <div>
    <navbar />
    <main class="profile-page">
      <section class="relative block h-500-px">
        <div
          class="absolute top-0 w-full h-full"
          :class="colors.bgClass"
        >
        </div>
        <div
          class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
          style="transform: translateZ(0);"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-blueGray-100 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </section>
      <section class="relative py-16 bg-blueGray-100">
        <div class="container mx-auto px-4">
          <div
            class="relative flex flex-col min-w-0 break-words bg-blueGray-100 w-full mb-6 shadow-xl rounded-lg -mt-64"
          >
            <div class="px-6">
              <div class="flex flex-wrap justify-center mb-12">
                <div
                  class="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center"
                >
                  <div class="relative">
                    <div class="text-4xl flex bg-blueGray-200 text-blueGray-600 align-center shadow-xl rounded-full h-auto text-white items-center justify-center border-none absolute -m-16 -ml-20 lg:-ml-16 profile">
                      <i class="fas fa-user"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-12 relative"> <br></div>
              <div v-if="!isLoading" class="text-center mt-12 py-4">
                <h3
                  class="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2"
                >
                  {{`${user.name} ${user.last_name}`}}
                </h3>
                <div
                  class="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold"
                >
                  {{`${user.email}`}}
                </div>
                <div
                  class="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold"
                >
                  {{`${user.phone_number}`}}
                </div>
                <router-link :to="{path:`/shop/${selected}/orders`}">
                  <button
                    id="sign-up-button"
                    class="bg-emerald-400 text-white mt-2 active:bg-blueGray-300 active:text-white text-sm font-bold px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                  >
                    Ver mis pedidos
                  </button>
                </router-link>
                <button
                  id="sign-up-button"
                  class="bg-red-400 text-white mt-2 active:bg-blueGray-300 active:text-white text-sm font-bold px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  @click="handleCerrarSesion"
                >
                  Cerrar sesión
                </button>
              </div>
              <Skeletor v-else width="100%" height="200" class="mt-12 rounded-lg mb-3" square/>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer-component />
  </div>
</template>

<script>
import Navbar from "@/components/Navbars/IndexNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";
import { onMounted, reactive, ref } from '@vue/runtime-core';
import useFetch from '@/use/useFetch.js'
import useCart from '@/use/useCart.js'
import useAuth from '@/use/useAuth.js'
import { useRouter } from 'vue-router';
import { Skeletor } from 'vue-skeletor'
import useProps from '@/use/useProps.js'
export default {
  components: {
    Navbar,
    FooterComponent,
    Skeletor
  },
  setup() {
    const {
      get:getFetch 
    } = useFetch()

    const {
      colors,
      selected
    } = useProps()

    const {
      get:getCart
    } = useCart()

    const {
      logout
    } = useAuth()
    const user = reactive({
      name: "",
      last_name: "",
      phone_number: "",
      email: "",
    })

    const isLoading = ref(false)
    const router = useRouter()

    const handleCerrarSesion = async() => {
      await getFetch('logout')
      logout()
      getCart()
      router.push({path:'/shop/'})
    }

    onMounted(async() => {
      isLoading.value = true
      const {error, user:userData} =  await getFetch('user-profile')
      if(!error){
         Object.assign(user, userData)
        isLoading.value = false

      }else{
        router.push({path:'/shop/'})
      }

    })
    return {
      user,
      colors,
      selected,
      handleCerrarSesion,
      isLoading
    }
  },
}
</script>

<style scoped>
  .profile{
    width: 150px;
    height: 150px;
  }
</style>
