import { useRouter } from "vue-router";
import useProductos from "./useProductos";

const { reactive, toRefs } = require("vue");


const state = reactive({
    selected:'',
    colors:{
        bgClass:'',
        secondaryClass:''
    }
})

const useProps = () => {
    const router = useRouter()
    const {
        selectedCategory
    } = useProductos()

    const setSelected = (selected) => {
        state.selected = selected
        setColors(state.selected)
    }

    const setColors = (selected) => {
        
        switch(selected){
            case 'hombres':
                state.colors = {
                    bgClass:'bg-blueGray-800',
                    secondaryClass:''
                }
                break;
            case 'mujeres':
                state.colors = {
                    bgClass:'bg-red-200',
                    secondaryClass:''
                }
                break
            default:
                selectedCategory.value = null
                router.push({path:'/shop/'})
                break
        }
    }

    return{
        ...toRefs(state),
        setSelected
    }
}

export default useProps