<template>
    <div class="flex flex-wrap mb-5 relative">
        <div class="w-3/12">
            <Skeletor v-if="!loaded" width="90" height="90" class="rounded-lg" square/>
            <img v-if="product.variantes.imagens.length <= 0" v-show="loaded" @load="handleLoad" :alt="product.variantes.descripcion_corta" :src="product.url_imagen" class="img-producto shadow-lg rounded-lg img-fit">
            <img v-else :alt="product.variantes.descripcion_corta" v-show="loaded" @load="handleLoad" :src="product.variantes.imagens[0].url_imagen" class="img-producto shadow-lg rounded-lg img-fit">
        </div>
        <div class=" w-9/12 px-4">
            <span class="font-semibold text-blueGray-500 m-0">{{product.variantes.descripcion_corta}}</span>
            <p class="text-blueGray-500 m-0"><small>{{product.variantes.tallas.nombre}}</small></p>
            <p class="m-0" :class="ofertum.hasOfertum ? 'text-red-500':'text-blueGray-500'"><small>{{$filters.currency(ofertum.precio_total)}}{{ofertum.hasOfertum ? ` antes ${$filters.currency(ofertum.original_price)}`: ''}}</small></p>
            <div class="flex bg-blueGray-200 rounded-full" style="width:fit-content">
                <button class="text-sm px-3" @click="handleAddCart(product.cantidad - 1)">
                    <i class="fas fa-minus"></i>
                </button>
                <small>{{product.cantidad}}</small>
                <button class="text-sm px-3  active:outline-none" type="button" @click="handleAddCart(product.cantidad + 1)">
                    <i class="fas fa-plus"></i>
                </button>
            </div>
            <button
                class="btn-delete absolute bg-red-400 text-white shadow-lg font-normal items-center justify-center align-center rounded-full "        
                type="button"
                @click="handleDeleteElement"
            >
                <i class="fas fa-trash"></i>
            </button>
        </div>
    </div>
</template>

<script>
import useCart from "@/use/useCart.js"
import { ref, toRefs } from '@vue/reactivity'
import { notify } from '@kyvg/vue3-notification'
import { Skeletor } from 'vue-skeletor'
import Swal from 'sweetalert2'
import { computed } from '@vue/runtime-core'
export default {
    props:{
        product:{
            type:Object,
            default:null
        }
    },
    components:{
        Skeletor
    },
    setup(props) {
        const {
            deleteElement,
            add:addCart,
            get
        } = useCart()

        const{product} = toRefs(props)

        const loaded = ref(false)

        const handleDeleteElement = async() => {
            const result = await Swal.fire({
                title: '¿Estas Seguro/a?',
                text: "Este producto se eliminará de su carrito",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si, elminar',
                customClass: {
                    confirmButton: 'bg-emerald-400 text-white active:bg-blueGray-300 active:text-white text-sm font-bold uppercase px-3 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1  ease-linear transition-all duration-150 mb-3',
                    cancelButton: 'bg-red-400 text-white active:bg-blueGray-300 active:text-white text-sm font-bold uppercase px-3 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1  ease-linear transition-all duration-150 mb-3'
                },
                buttonsStyling:false,
                cancelButtonText: 'Cancelar'
            })
            if(!result.isConfirmed) return

            const data = {
                uuid_variante:product.value.variantes.uuid,
                uuid_talla:product.value.variantes.tallas.uuid,
            }
            const {error, message} = await deleteElement(data)
            
                notify({
                    type:error?'error':'success',
                    text:message
                })
            await get()
        }

        const ofertum = computed(() => {
            const {variantes:{tallas:{variantes:{ofertum,precio_total}}}} = product.value
            return {
                hasOfertum: ofertum.porcentaje > 0,
                original_price:precio_total,
                precio_total:precio_total - ((precio_total * ofertum.porcentaje) / 100)
            }
        })

        const handleAddCart = async(cantidad) => {
            if(cantidad <= 0){
                notify({
                    type: 'warning', 
                    title: '<bold>¡Cuidado!</bold>',
                    text: "No se cambiar la cantidad a valores menores de 1",
                })
                return
            }

            const data = {
                uuid_variante:product.value.variantes.uuid,
                uuid_talla:product.value.variantes.tallas.uuid,
                cantidad
            }
            const {error, message} = await addCart(data)
            if(error){
                notify({
                    type: 'error', 
                    title: '<bold>¡Error!</bold>',
                    text: message,
                })
            }else{
                notify({
                    type: 'success', 
                    title: '<bold>¡Exito!</bold>',
                    text: message,
                })
            }
            }



        const handleLoad = () => {
            loaded.value = true
        }
        return {
            handleDeleteElement,
            handleAddCart,
            loaded,
            handleLoad,
            ofertum
        }
    },
}
</script>

<style scoped>
  .img-fit{
    height: 90px;
    width: auto;
    object-fit: cover;
    aspect-ratio: 1 / 1;
  }
  .btn-delete{
      bottom: -10px;
      left: -10px;
      width: 30px;
        height: 30px;
  }

</style>