<template>
  <nav class="shadow top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-blueGray-100 mb-3">
    <div class="container px-4 mx-auto flex flex-wrap items-center justify-between">
      <div class="w-full relative flex justify-between">
        <router-link :to="{path:`/shop/${selected}`}" class="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-no-wrap uppercase text-white" href="#">
          <img :src="selected == 'hombres' ? logo_men : logo_woman" alt="" class="img-logo">
        </router-link>
        <ul class="flex flex-wrap items-center list-none ml-auto">
          <li class="nav-item">
            <router-link :to="token ? {path:'/shop/'+selected+'/profile'} : {path:'/shop/'+selected+'/auth/login'}">
            <button ref="btnRef" @mouseenter="toggleTooltip()" @mouseleave="toggleTooltip()"   class="text-blueGray-600 background-transparent font-bold uppercase px-3 py-1  outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
              <i class="fas fa-user"></i>
            </button>
            </router-link>
            <div ref="popoverRef" :class="{'hidden': !popoverShow, 'block': popoverShow}" class="bg-blueGray-200 border-0 mr-3 block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg">
              <div>
                <div class="bg-blueGray-200 text-blueGray-600 text-white opacity-75 font-semibold p-3 mb-0 border-b border-solid border-blueGray-100 rounded-lg">
                  {{token ? 'Ver perfil' : 'Inicia sesión'}}
                </div>
              </div>
            </div>
          </li>
          <li class="nav-item">
            <button 
              class="text-blueGray-600 background-transparent font-bold uppercase px-3 py-1 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
              type="button"
              @click="togleSideCart(!showCart)"
            >
              <i class="fas fa-shopping-bag"></i>
              <span class="absolute text-xs font-semibold py-1 px-2 uppercase rounded uppercase -ml-3 mt-3 badge-m" :class="badgeCantCart">
                {{totalProducts}}
              </span>
            </button>
          </li>
          <li class="nav-item" v-if="showInMenu">
            <button 
              class="text-blueGray-600 background-transparent font-bold uppercase px-3 py-1 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
              type="button"
              @click="showCategories = !showCategories"
            >
              <i class="fas fa-search"></i>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </nav>


</template>

<script>
import { ref } from '@vue/reactivity'
import { createPopper } from "@popperjs/core"
import useAuth from "@/use/useAuth"
import useCart from "@/use/useCart"
import { computed } from '@vue/runtime-core'
import logo_woman from "@/assets/img/logo.png" 
import logo_men from "@/assets/img/logo_mens.png"
import useProps from '@/use/useProps.js'
import useProductos from '@/use/useProductos.js'
export default {
  setup() {
    const {
      token
    } = useAuth()

    const{
      selected
    } = useProps()
    
    const {
      showCategories,
      showInMenu
    } = useProductos()

    const {
      products,
      showCart,
      togleSideCart
    } = useCart()
    const popoverShow = ref(false)
    const btnRef = ref(null)
    const popoverRef = ref(null)
    const toggleTooltip = () => {
      if(popoverShow.value){
        popoverShow.value = false;
      } else {
        popoverShow.value = true;
        createPopper(btnRef.value, popoverRef.value, {
          placement: "bottom"
        });
      }
    }

    const badgeCantCart = computed(() => {
      if(selected.value == 'hombres'){
        return ' text-white bg-blueGray-800 '
      }else{
        return 'text-red-500 bg-red-200 '
      }
    })

    const totalProducts = computed(()=>{
      return products.value.reduce((acc, el) => (acc + el.cantidad), 0)
    })


    return{
      badgeCantCart,
      products,
      totalProducts,
      popoverShow,
      toggleTooltip,
      btnRef,
      token,
      selected,
      popoverRef,
      logo_woman,
      logo_men,
      showCart,
      togleSideCart,
      showCategories,
      showInMenu
    }
  },
}
</script>

<style scoped>
  .img-logo{
    width: auto;
    height: 48px;
  }
  .badge-m{
    margin-left: -3px;
  }
  
</style>