import { createApp } from "vue";
import { createWebHistory, createRouter } from "vue-router";

// styles

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";

// mouting point for the whole app

import App from "@/App.vue";

// layouts

import Auth from "@/layouts/Auth.vue";

import Profile from "@/views/Profile.vue";

import Notifications from '@kyvg/vue3-notification'
import Maska from "maska"
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import useAuth from "./use/useAuth";
import useCart from "./use/useCart";

// routes

const routes = [
  {
    path: "/shop/:selected/cart",
    component: () => import("@/views/Cart.vue")
  },
  {
    path: "/shop/:selected/auth",
    redirect: "/shop/:selected/auth/login",
    component: Auth,
    children: [
      {
        path: "/shop/:selected/auth/login",
        component: () => import("@/views/auth/Login.vue"),
      },
      {
        path: "/shop/:selected/auth/register",
        component: () => import("@/views/auth/Register.vue"),
      },
    ],
  },
  {
    path:"/shop/",
    component:() => import("@/views/SelectShop.vue"),
  },
  {
    path: "/shop/:selected/profile",
    component: Profile,
  },
  {
    path: "/shop/:selected/orders",
    component: () => import("@/views/Pedidos.vue"),
  },
  {
    path: "/shop/:selected/product/:id",
    component: () => import("@/views/Product.vue"),
  },
  {
    path: "/shop/:selected/",
    component: () => import("@/views/Index.vue"),
  },
  {
    path:"/shop/:selected/:page",
    component: () => import("@/views/Index.vue")
  },
  {
    path:"/shop/:selected/:page/:category",
    component: () => import("@/views/Index.vue")
  },
  { path: "/shop/:pathMatch(.*)*", redirect: "/shop/" }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const firebaseConfig = {
  apiKey: "AIzaSyBJZ3B4HCcNCQhp2-DFwuYeOuwEqgZlDQg",
  authDomain: "sportcloset-deb6c.firebaseapp.com",
  projectId: "sportcloset-deb6c",
  storageBucket: "sportcloset-deb6c.appspot.com",
  messagingSenderId: "368996913093",
  appId: "1:368996913093:web:0385ecebcb131b115485ac",
  measurementId: "G-6MHTZ9KVXJ"
};

const firebaseapp = initializeApp(firebaseConfig);
getAnalytics(firebaseapp);

const { setToken, getToken } = useAuth()
setToken(getToken())

const app = createApp(App)

app.use(router)
app.use(Notifications)
app.use(Maska)

router.isReady().then(() => {
  app.mount('#app')
  useCart().get()
})


app.config.globalProperties.$filters = {
  currency(ammount){
    if(!ammount) return new Intl.NumberFormat("es-MX", {style: "currency", currency: "MXN"}).format(0)
    return new Intl.NumberFormat("es-MX", {style: "currency", currency: "MXN"}).format(ammount)
  }

}
