import useAuth from "./useAuth"
// const API_URL = "http://127.0.0.1:8000/api" //sandbox
// const API_URL = "https://sandbox.api.sportcloset.mx/api"
const API_URL = "https://api.sportcloset.mx/api"

const useFetch = () => {
    const post = async(route, data) => {
        const { token, logout } = useAuth()
        const authentification = `Bearer ${token.value}`
        const response = await fetch(`${API_URL}/${route}`, {
            method: 'POST', 
            mode: 'cors',
            cache: 'no-cache', 
            credentials: 'same-origin', 
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': authentification
            },
            redirect: 'follow', 
            body: JSON.stringify(data) 
          })
        const result = await response.json()
        if(result.message == 'Unauthenticated.'){
            logout()
            return {
                error:true,
                message:"Necesita iniciar sesión"
            }
        }else{
            return result
        }
    }

    const get = async(route) => {
        const { token, logout } = useAuth()
        const authentification = `Bearer ${token.value}`
        const response = await fetch(`${API_URL}/${route}`, {
            method: 'GET', 
            mode: 'cors',
            cache: 'no-cache', 
            credentials: 'same-origin', 
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': authentification
            },
            redirect: 'follow', 
          })
        const result = await response.json()
        if(result.message == 'Unauthenticated.'){
            logout()
            return {
                error:true,
                message:"Necesita iniciar sesión"
            }
        }else{
            return result
        }
    }

    return {
        post,
        get
    }
}

export default useFetch