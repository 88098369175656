import { reactive,toRefs } from "vue";
import { computed } from "@vue/runtime-core";
import paginate from "jw-paginate";
import useFetch from "./useFetch";

const state = reactive({
    productos:[],
    product:null,
    config:{
        currentPage:1,
        perPage:12,
        total:0
    },
    isLoading:true,
    pagination:{
        numPage:0,
        active:3
    },
    categorias:[],
    showCategories:false,
    showInMenu:false,
    selectedCategory:null,
})

const useProductos = () => {
    const { post } = useFetch()
    
    const getProductos = async(data) => {
        state.isLoading = true
        const {error, products, total} = await post('products', data)
        if(error){
            state.isLoading = true
            state.productos = []
            state.config.total = 0
        }else{
            state.productos = products
            state.config.total = total
            state.isLoading = false
        }
    }

    const getCategorias = async(data) => {
        state.categorias = []
        const {error,categorias} = await post('categorias',data)
        if(!error){
            state.categorias  = categorias
        }else{
            state.categorias = []
        }
    }

    state.pagination = computed(()=>{
        const _pagination = paginate(
            state.config.total,
            state.config.currentPage,
            state.config.perPage,
            3
        )
        if (state.pagination && state.pagination.totalPages < state.config.currentPage && state.pagination.totalPages >= 1) {
            state.config.currentPage =  state.pagination.totalPages
        }

        return _pagination
    })

    const setConfig = (config) => {
        state.config = config;
    }

    const getProduct = async(id) => {
        if(!id){
            state.product = null
            state.isLoading = false
            return
        }
        state.isLoading = true
        const {error, product} = await post('product', {id})
        if(!error){
            state.product = product
            state.isLoading = false
        }else{
            state.product = null
            state.isLoading = true
        }
    }

    return{
        ...toRefs(state),
        getProductos,
        getProduct,
        setConfig,
        getCategorias
    }
}

export default useProductos

