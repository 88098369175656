import { reactive, toRefs } from "vue"
import useFetch from "./useFetch"

const state = reactive({
    directions: [],
    products:[],
    deletes:[],
    isLoading:false,
    cartIsLoading:false,
    directionsIsLoading:false,
    showCart:false,
    totalAPagar:0.0,
    costoEnvio:0.0
})

const useCart = () => {
    const { post, get:getFetch } = useFetch()
    const  add = async(data) => {
        state.isLoading = true
        const {error, message} = await post('add-cart', data)
        state.isLoading = false
        get()
        return {
            error,
            message
        }
    }

    const getDirections = async() => {
        state.directionsIsLoading = true
        const {error, directions:directionsData} = await getFetch('get-directions')
        if(error){
            state.directions = []
            state.directionsIsLoading = false
        }else{
            state.directions = directionsData
            state.directionsIsLoading = false
        }
    }

    const addDirection = async(data) => {
        state.directionsIsLoading = true
        const response = await post('add-direction',{...data})
        await getDirections()
        state.directionsIsLoading = false
        return response
    }
    

    const editDirection = async(data) => {
        state.directionsIsLoading = true
        const response = await post('update-direction',{...data})
        await getDirections()
        state.directionsIsLoading = false
        return response
    }

    const deleteDirection = async(uuid) => {
        state.directionsIsLoading = true
        const response = await post('delete-direction',{uuid})
        await getDirections()
        state.directionsIsLoading = false
        return response
    }

    const getCostoEnvio = async() => {
        const {costo} = await getFetch('costo-envio')
        state.costoEnvio = costo
    }

    const get = async() => {
        state.cartIsLoading = true
        const { error, products, deletes, total} = await getFetch('get-cart')
        if(error){
            state.products = []
            state.cartIsLoading = false
            state.deletes = []
            state.totalAPagar = 0.0
        }else{
            state.products = products
            state.cartIsLoading = false
            state.deletes = deletes
            state.totalAPagar = total
        }
    }

    const deleteElement = async(data) => {
        return await post('delete-cart-element',data)
    }

    const togleSideCart = (value) => {
        state.showCart = value
    }

    const createPayment = async(data) => {
        return await post("create-payment", {...data})
    }

    const cancelPayment = async(uuid_pedido) => {
        return await post("cancel-payment", {uuid_pedido})
    }

    const payPayment = async(uuid_pedido, order_id) => {
        return await post("pay-payment", {uuid_pedido, order_id})
    }

    const validateCart = async() =>{
        return await getFetch("validate-cart")
    }

    const notifyAdmin = async() => {
        const formData = new FormData()
        formData.append('to_mail','sandrasof_chz@hotmail.com')
        formData.append('to_name','Sandra Sofia')
        formData.append('subject',`Se ha creado un nuevo pedido entre al administrador para verificarlo.`)
        await post()
        
        const response = await fetch('https://sportcloset.mx/resources/services/aviso_to_admin_pedido_nuevo.php',{
            method:'POST',
            body:formData
        })

        return response
    }

    const notifyUser = async() => {
        
        const {user} =  await getFetch('user-profile')
        const formData = new FormData()
        formData.append('to_mail',user.email)
        formData.append('to_name',user.name)
        formData.append('subject','Registro de un pedido en sportcloset.mx')
        
        
        return await fetch('https://sportcloset.mx/resources/services/aviso_pedido_nuevo.php',{
            method:'POST',
            body:formData
        })

    }
    

    return{
        ...toRefs(state),
        add,
        get,
        getCostoEnvio,
        getDirections,
        deleteElement,
        togleSideCart,
        editDirection,
        addDirection,
        deleteDirection,
        createPayment,
        validateCart,
        cancelPayment,
        payPayment,
        notifyAdmin,
        notifyUser
    }
}

export default useCart

