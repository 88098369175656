<template>
  <div id="app">
    <notifications position="top center" />
    <SidebarCart />
    <router-view v-slot="{ Component, route}">
      <transition name="fade" mode="out-in">
        <component :is="Component" :key="route" class="bg-red-200"/>
      </transition>
    </router-view>

  </div>
</template>

<script>
import SidebarCart from "@/components/Sidebar/SidebarCart.vue"
import { useRouter } from 'vue-router'
import { onMounted, watch } from '@vue/runtime-core'
import useProps from '@/use/useProps.js'
export default {
  components:{
    SidebarCart
  },
  setup() {
    const router = useRouter()
    const {
      params:{selected}
    } = router.currentRoute.value

    const {
      setSelected
    } = useProps()

    onMounted(() => {

      if(!selected){
        router.push({path:'/shop/'})
      }else{
        setSelected(selected)
      }
    })

    watch(() => router.currentRoute.value.params, (newValue) => {
      const { selected } = newValue
      setSelected(selected)
    })

  },
}
</script>


<style>
.vue-skeletor {
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.12); }
  .vue-skeletor:not(.vue-skeletor--shimmerless):after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3), rgba(37, 22, 22, 0));
    animation: shimmer 1.5s infinite;
    content: ''; }
  .vue-skeletor--rect, .vue-skeletor--circle {
    display: block; }
  .vue-skeletor--circle {
    border-radius: 50%; }
  .vue-skeletor--pill, .vue-skeletor--text {
    border-radius: 9999px; }
  .vue-skeletor--text {
    line-height: 1;
    display: inline-block;
    width: 100%;
    height: inherit;
    vertical-align: middle;
    top: -1px; }

@keyframes shimmer {
  100% {
    transform: translateX(100%); } }


.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

:root {
    --animate-duration: .75s;
}


</style>