<template>
  <div>
    <main>
      <section class="relative w-full h-full py-40 min-h-screen">
        <div
          class="absolute top-0 w-full h-full bg-no-repeat bg-full"
          :class="colors.bgClass"
          :style="`background-image: url('${registerBg2}');`"
        ></div>
        <router-view />
        <footer-small absolute />
      </section>
    </main>
  </div>
</template>
<script>
import FooterSmall from "@/components/Footers/FooterSmall.vue";
import registerBg2 from "@/assets/img/register_bg_2.png";
import useProps from '@/use/useProps.js'
export default {
  components:{
    FooterSmall
  },
  setup() {
    const {
      colors
    } = useProps()


    return {
      registerBg2,
      colors
    }
  },
}
</script>
