import {get,set} from "local-storage";
import { reactive, toRefs } from "vue";

const state = reactive({
    user:null,
    token:null
})

const useAuth = () => {

    const getToken = () => {
        return get("token");
    }

    const setToken = (token) => {
        if(token != 'undefined' && token != null){
            set("token", token)
            state.token = token
        }else{
            set("token", null)
            state.token = null
        }
    }

    const register = async() => {

    }
    

    const logout = async() => {
        setToken(null)
    }

    return {
        ...toRefs(state),
        register,
        logout,
        getToken,
        setToken
    }

}

export default useAuth