<template>
    <transition name="slide">
      <nav
        v-show="showCart"
        class="right-0 md:block fixed top-0 bottom-0 overflow-y-auto flex-row flex-nowrap overflow-hidden shadow-xl bg-blueGray-100 flex flex-wrap justify-between z-100 py-4 px-6 md:w-3/12 sm:w-full"
      >
        <div class="w-full">
          <div class="flex">
            <button
              class="bg-blueGray-200 text-bg-blueGray-600 active:bg-blueGray-300 active:text-white text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 mr-2 ease-linear transition-all duration-150 mb-3"
              type="button"
              @click="togleSideCart(false)"
            >
              <i class="fas fa-times"></i>
            </button>
            <router-link :to="{path:`/shop/${selected}/cart`}" class="w-full" @click="togleSideCart(false)">
              <button
                class="bg-blueGray-200 text-bg-blueGray-600 active:bg-blueGray-300 active:text-white text-sm font-bold uppercase px-3 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150 mb-3"
                type="button"
              >
                <span>Pagar {{$filters.currency(totalAPagar)}}</span>
              </button>
            </router-link>
          </div>
          <div class="container mx-auto">
            <div class="flex ">
              <div class="w-full">
                <div class="container mx-auto">
                  <CartProductCart v-for="(product, index) in products" :key="index" :product="product"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </transition>
</template>
); }

<script>
import useCart from "@/use/useCart.js"
import CartProductCart from "@/components/Cards/CartProductCart.vue"
import useProps from '@/use/useProps.js'
export default {
  components:{
    CartProductCart
  },
  setup() {
    const {
      showCart,
      togleSideCart,
      products,
      totalAPagar
    } = useCart()

    const {
      selected,
      colors
    } = useProps()

    return{
      showCart,
      selected,
      togleSideCart,
      products,
      totalAPagar,
      colors
    }
  },
}
</script>

<style scoped>
  .z-100{
    z-index: 100;
  }
  .slide-enter-active {
    transition: all .3s ease;
    transform: translateX(0px);
  }
  .slide-enter-to{
    transform: translateX(0px);
  }
  .slide-leave-active {
    transition: all .8s ease;
    transform: translateX(500px);
  }
  .slide-enter, .slide-leave-to{
    transform: translateX(500px);
    opacity: 0;
  }
  .w-380-px{
    min-width: 380px;
  }

</style>
